@import '../../../styles/gifts/sass/basics';

.bb-stats-section-with-animation {
  background-color: $yellow-800;
  position: relative;
  $offset: max(calc(50% - 250px), 20px);
  clip-path: inset(0% $offset 0% $offset round 10px);
  margin: 64px auto;
  background-image:
          url('/static/textures/concern-letters/concern-o-turquoise-600.svg'),
          url('/static/textures/concern-letters/concern-n-turquoise-600.svg');
  background-size: 1002px auto, 991px auto;
  background-position: calc(50% - 388px) 413px, calc(50% + 442px) -390px;
  background-repeat: no-repeat;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 110px;
    color: $white;
    font-weight: bold;
    line-height: $line-height-small;
    padding: 80px 32px 32px;
  }
  &__heading-with-items {
    display: flex;
    flex-direction: column;
    gap: 72px;
    text-align: center;
  }
  &__heading {

    font-size: 40px;
    font-weight: bold;
  }
  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    gap: 8px;

    &-heading {
      color: $turquoise-1000;
      font-size: 40px;
      background: $white;
      padding: 8px 12px;
    }
    &-subheading {
      font-size: 28px;
    }
  }
  &__button {}

  @include media-breakpoint-up(md) {
    $offset: max(calc(50% - 340px), 20px);
    clip-path: inset(0% $offset 0% $offset round 10px);
    margin: 80px auto;
    background-position: calc(50% - 430px) 300px, calc(50% + 544px) -390px;
    &__button {
      align-self: flex-end;
    }
  }

  @include media-breakpoint-up(lg) {
    $offset: max(calc(50% - 480px), 70px);
    clip-path: inset(0% $offset 0% $offset round 10px);
    background-size: 961px auto, 778px auto;
    background-position: calc(50% - 560px + 28px) 122px, calc(50% + 57px + 389px) -244px;
    &__items {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    }
    &__item {
      flex-basis: 34%;
    }
  }

  @include media-breakpoint-up(xl) {
    $offset: max(calc(50% - 640px), 70px);
    clip-path: inset(0% $offset 0% $offset round 10px);
    background-size: 1119px auto, 778px auto;
    background-position: calc(50% - 530px) calc(50% + 200px), calc(50% + 600px) calc(50% - 100px);
    transition: all 1s;
    margin: 90px auto;

    &--full-width {
      clip-path: inset(0% 0% 0% 0%);
      margin: 0;
      padding: 90px 0;
    }

    &__content {
      padding: 80px 72px 32px;
    }
    &__heading-with-items {
      align-items: flex-start;
      text-align: left;
      gap: 80px;
    }
    &__heading {
      font-size: 60px;
    }
    &__items {
      justify-content: space-between;
      gap: 57px
    }
    &__item {
      align-items: flex-start;
      flex-basis: 21%;
      &-heading {
        font-size: 48px;
      }
      &-subheading {
        font-size: 36px;
      }
    }
  }

  @media (min-width: 1440px) {
    background-size: 1604px auto, 1088px auto;
    background-position: calc(50% - 800px) calc(50% + 440px), calc(50% + 600px) calc(50% - 100px);
    &__heading-with-items {
      flex-direction: row;
      gap: 42px;
    }
    &__heading {
      max-width: 443px;
    }
    &__items {
      margin-top: 40px;
    }
    &__item {
      flex-basis: 34%;
    }
  }

  @media (min-width: 1920px) {
    background-size: 1119px auto, 778px auto;
    background-position: calc(50% - 530px) calc(50% + 200px), calc(50% + 600px) calc(50% - 100px);
    &__heading-with-items {
      gap: 117px
    }
  }
}
